assessment-edit {
    .col-assessment-settings-form {
        @include make-md-column(6);

        padding: 0 30px;
    }

    .height-separator {
        margin-top: 10px;
    }

    .btn-toolbar > .btn {
        width: auto;
        border-radius: 3px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        height: 40px;
        padding: 0 20px;
        margin-left: 10px;
    }

    select {
        &.form-control {
            background: none;
            border-color: $snow;
            box-shadow: none;
            padding-right: 30px;
            cursor: default;
            transition: none;
            appearance: menulist;

            &:hover {
                background-color: #fff;
                border-color: $snow;
                box-shadow: none;
                transform: none;
            }

            &.form-control[disabled] {
                background: #eee;
            }
        }
    }
}
