referral-add-communication-modal {
    select {
        &.form-control {
            background: none;
            border-color: $snow;
            box-shadow: none;
            padding-right: 30px;
            cursor: default;
            transition: none;
            appearance: menulist;

            &:hover {
                background-color: #fff;
                border-color: $snow;
                box-shadow: none;
                transform: none;
            }

            &.form-control[disabled] {
                background: #eee;
            }
        }
    }
}
