content-publisher {
    .content-publisher-select-box {
        height: 350px;

        select {
            height: 100%;
        }
    }

    .content-publisher-controls {
        button {
            margin: 10px 0;
            width: 100%;
        }
    }
}
