referral-activity-prior-authorization {
    .flex-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .header-referral-activity {
        margin-bottom: 20px;
        margin-top: 40px;
    }

    .nav-referral-activity {
        margin-bottom: 40px;
        margin-top: 10px;

        a {
            border-right: solid 1px $link-color;
            padding-left: 3px;
            padding-right: 6px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                border-right: none;
            }
        }
    }

    .insurance-plans {
        margin-bottom: 20px;
    }

    .insurance-plan-tab {
        border: 1px solid #ddd;
        border-radius: 0 3px 3px;
        padding: 20px;
    }
}
