patient-profile-therapies {
    %action {
        a {
            font-size: 12px;
            position: relative;
            top: 5px;
        }
    }

    .therapy-container {
        background-color: #fff;
        color: $shark;
        border-radius: 4px;
        border: 1px solid #ddd;
        margin-bottom: 20px;

        .therapy-header {
            background-color: $snow;
            padding: 5px 10px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border-bottom: 1px solid $snow;

            &.therapy-no-medications {
                border-radius: 3px;
            }

            .therapy-action {
                @extend %action;
            }
        }

        .medication-container {
            padding: 5px 10px;
            border-bottom: 1px solid $snow;

            .medication-container-title {
                color: $shark;
                font-size: 12px;
            }

            .medication-action {
                @extend %action;
            }

            &.medication-ended {
                opacity: 0.6;

                &:hover {
                    opacity: 1;
                }

                .medication-end-label {
                    font-size: 8px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: $brand-danger;
                }
            }

            &:hover {
                background-color: $snow;
            }

            .rx-container {
                border-left: 2px solid #ddd;
                padding-top: 10px;
                padding-left: 15px;
                padding-bottom: 5px;
                margin-top: 5px;

                .col-md-7th {
                    @include make-md-column(1.7);
                }

                .col-md-8th {
                    @include make-md-column(1.5);
                }

                a {
                    cursor: pointer;
                    font-size: 12px;
                }
            }
        }
    }
}
