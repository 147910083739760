therigy-unknown-error {
    .error-display {
        @extend %error-display;
    }

    .support-email {
        color: $brand-danger;
    }

    .support-email,
    .support-phone-number {
        text-decoration: underline;
    }
}
