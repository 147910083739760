.content-monograph-display {
    .content-monograph-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: tint($matisse, 91%);
        padding: 0.7em 20px;

        .content-monograph-group-name {
            font-size: 2rem;
        }

        img {
            max-height: 2em;
        }
    }

    .content-monograph-content {
        padding: 20px;
    }
}
