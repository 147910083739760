content-library-edit-monograph {
    .page-wrapper-fluid {
        position: absolute;
        top: $header-height + $navbar-height;
        bottom: 0 + $footer-height;
        left: 0;
        display: flex;
        width: 100%;
        overflow: hidden;

        // Required for Firefox
        min-height: 0;
    }

    .edit-monograph-sidebar {
        background-color: tint($matisse, 90%);
        padding: 20px;
        min-width: 300px;
        overflow: auto;
        flex: 0 0 300px;

        // Required for Firefox
        min-height: 0;

        ul {
            padding: 5px;
            list-style: none;
        }

        .content-version-container-scrollspy-link {
            color: $storm;
            display: inline-block;
            padding: 10px 0;
            height: 40px; // This prevents the links from bouncing when using the scrollspy

            &:focus {
                text-decoration: none;
            }

            &:hover {
                color: $brand-primary;
                text-decoration: none;
            }
        }

        .delete-association-btn {
            color: shade($brand-danger, 10);
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .edit-monograph-scrollspy-item-header {
        color: $storm;
        margin-top: 25px;
        font-size: 18px;
    }

    .edit-monograph-scrollspy-item.active {
        a {
            color: $brand-primary;
            border-bottom: 3px solid $brand-primary;
        }
    }

    .edit-monograph-header {
        display: flex;
        justify-content: space-between;
    }

    .edit-monograph-content {
        background-color: #fff;
        padding: 20px;
        overflow: auto;
        width: 100%;
    }

    .content-version-container {
        margin-bottom: 20px;
    }

    %header {
        color: $cinder;
        margin-bottom: 20px;
    }

    %name {
        display: inline-block;
        font-weight: bold;

        &::after {
            content: '|';
            padding: 0 5px;
        }
    }

    %label {
        display: inline-block;
        margin-right: 5px;
    }

    .content-group-header {
        @extend %header;

        font-size: 16px;

        .content-group-name {
            @extend %name;
        }

        .content-group-label {
            @extend %label;
        }
    }

    .content-header {
        .content-header-title {
            @extend %name;
        }

        .content-header-label {
            @extend %label;
        }
    }

    .copy-to-clipboard-btn {
        cursor: pointer;
    }
}
