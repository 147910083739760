.add-rule-form {
    .label-tooltip-group {
        display: flex;
        justify-content: space-between;
    }

    /**
    There is an unusual circumstance where the normal cascade for the has-error doesn't work. This is
    to help address it.
   */
    label.has-error {
        color: $brand-danger;
    }

    .activity-due-date-group {
        display: flex;
        align-items: center;
        width: 100%;

        & > * {
            margin-right: 0.5rem;
        }

        & > *:last-child {
            margin-right: 0;
        }

        input.has-error,
        .react-select.has-error {
            border: 1px solid $brand-danger;
            border-radius: 3px;
        }

        .react-select.has-error {
            height: 40px;
        }

        .due-date {
            width: 20%;
        }

        .before-after {
            width: 30%;
        }

        .date-type {
            width: 50%;
        }
    }

    table.therigy-table.add-filter-table {
        margin-top: 1rem;

        .condition-header {
            width: 20%;
        }

        .filter-header {
            width: 70%;
        }

        .action-header {
            width: 10%;
        }

        .condition-cell {
            display: flex;
            justify-content: space-between;

            .condition-text {
                align-self: center;
            }

            button {
                align-self: flex-start;
            }
        }

        .filter-cell {
            display: flex;
            position: relative;
            justify-content: space-between;
            align-items: center;
            border-top: 1px dashed #ddd;
            margin-top: 1rem;
            padding-top: 2rem;

            &:first-of-type {
                padding-top: 0;
                padding-bottom: 0.5rem;
                margin-top: 0;
                border-top: none;
            }

            .and-text {
                position: absolute;
                top: -1.15rem;
                left: 4rem;
            }

            .remove-filter-group {
                align-self: flex-start;
            }

            .filters {
                display: flex;
                flex-direction: column;
                flex-basis: 95%;
                justify-content: space-between;

                .filter-options {
                    display: flex;
                    margin-bottom: 0.5rem;
                    align-items: center;

                    .or-text {
                        display: flex;
                        align-items: center;
                        min-width: 2rem;
                    }

                    button:first-of-type {
                        margin-right: 0.5rem;
                    }

                    .add-filter {
                        color: $brand-success;
                    }

                    .remove-filter {
                        color: $brand-danger;
                    }

                    .filter-value,
                    .filter-operator {
                        margin-right: 0.5rem;
                    }

                    .filter-operator {
                        width: 40%;
                    }

                    .filter-value {
                        width: 40%;
                    }

                    .custom-date-picker-input {
                        height: 38px;
                        margin-right: 0.5rem;
                        width: 100%;
                    }
                }
            }
        }
    }
}
