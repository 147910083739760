engage-opt-in-modal {
    .enroll-modal-header {
        font-size: 20px;
    }

    .enroll-modal-panel {
        &.enroll-modal-panel-disabled {
            color: $overcast;
        }

        flex-grow: 1;
        min-width: 50%;

        .enroll-modal-content-container {
            height: 175px;
        }

        .enroll-modal-panel-header {
            font-size: 20px;
            font-weight: normal;
            margin-bottom: 20px;
        }

        .enroll-modal-panel-body {
            font-size: 16px;
            font-weight: normal;
            margin-bottom: 20px;
        }
    }

    .enroll-modal-divider {
        height: 100%;
        width: 1px;
        background: $glass;
        top: 0;
        bottom: 0;
        position: absolute;
        left: 50%;
    }

    .enroll-modal-patient-education-panel {
        padding-right: 15px;
    }

    .enroll-modal-patient-messaging-panel {
        padding-left: 15px;
    }

    .enroll-modal-panel-submit-button {
        @extend .pull-right;
    }

    .engage-modal-selection-screen {
        display: flex;
        justify-content: space-between;
        padding: 1px;
    }
}
