assessment-scrollspy {
    .assessment-scrollspy {
        .assessment-scrollspy-item {
            padding: 10px;

            a {
                color: $matisse;
                height: 30px;

                &:focus {
                    text-decoration: none;
                    color: $biscay;
                }
            }
        }

        .assessment-scrollspy-item-label {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: $manatee;
        }

        .assessment-scrollspy-item-icon {
            align-items: center;
            background-color: $matisse;
            border-radius: 50%;
            color: white;
            display: flex;
            font-size: 12px;
            height: 25px;
            justify-content: center;
            margin-right: 5px;
            text-align: center;
            width: 25px;
            flex: 0 0 25px;
        }

        .assessment-scrollspy-item.active {
            .assessment-scrollspy-item-icon {
                background-color: $biscay;
            }

            a {
                color: $biscay;
            }
        }
    }
}
