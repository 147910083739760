assessment-question-date {
    .assessment-question-date {
        max-width: 250px;

        .form-control {
            color: #666;

            &::placeholder {
                color: inherit;
            }
        }

        .assessment-special-question & {
            max-width: 100%;
        }
    }
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
    opacity: 1;
    color: $shark;
}
