.therapy-information-header, .therapy-information-content
  margin-bottom: 20px
  border: 1px solid #ddd
  border-radius: 4px

  .therapy-table-header
    padding-left: 20px
    padding-right: 20px
    background-color: $snow
    border-top-left-radius: 3px
    border-top-right-radius: 3px
    > label
      padding-top: 20px
      padding-bottom: 20px
      margin: 0
      float: left
      font-size: 12px

      &.therapy-name
        width: 20%
      &.icd-10-diagnosis
        width: 18%
      &.icd-9-diagnosis
        width: 18%
      &.care-opt-in, &.care-opt-out
        width: 16%
      &.actions
        width: 11%

  .table-wrapper
    max-height: 250px
    overflow-y: auto

.therapy-information-table

  .ui-select-search, .ui-select-match-text, .ui-select-toggle
    font-size: 12px

  .ui-select-toggle
    height: 30px !important

  .input-group
    input
      height: 30px !important
      font-size: 12px
    .input-group-addon
      height: 30px !important


  .input-group-addon
    padding: 2px
    cursor: pointer

  > thead > tr > th,
  > tbody > tr > td
    font-size: 12px

    &.therapy-name
      width: 20%
    &.icd-10-diagnosis
      width: 18%
    &.icd-9-diagnosis
      width: 18%
    &.care-opt-in, &.care-opt-out
      width: 16%
    &.actions
      width: 11%

  > tbody
    > tr
      height: 50px
      > td
        .ui-select-search, .ui-select-match-text
          font-size: 12px
        > .checkbox
          margin: 0
          > label
            cursor: pointer

.therapy-information-modal
  .modal-body
    > table
      table-layout: fixed

    .patients-input-group
      input
        margin: 0

    & .input-group-addon
      padding-top: 6px
      padding-right: 12px
