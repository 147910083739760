unassigned-patient {
    dd {
        height: 30px;
    }

    dl {
        border: 1px solid #ddd;
    }

    dl * {
        padding: 5px;
    }

    dl *:nth-child(4n) {
        background: #f0f0f0;
    }

    dt {
        float: left;
    }
}
