patient-notes {
    .patient-notes {
        textarea {
            resize: none;
        }

        &:hover {
            .patient-notes-readonly {
                + .patient-notes-btn-toolbar {
                    visibility: visible;

                    .patient-notes-edit-btn {
                        visibility: visible;
                    }

                    .patient-notes-cancel-btn,
                    .patient-notes-save-btn {
                        display: none;
                    }
                }
            }
        }

        .patient-notes-editable {
            + .patient-notes-btn-toolbar {
                display: block;

                .patient-notes-edit-btn {
                    visibility: hidden;
                }
            }
        }

        .patient-notes-readonly {
            cursor: pointer;
            background-color: white;

            + .patient-notes-btn-toolbar {
                visibility: hidden;
            }
        }

        .patient-notes-btn-toolbar {
            margin-top: 2px;
            text-align: right;

            .patient-notes-edit-btn {
                visibility: hidden;
            }
        }
    }
}
