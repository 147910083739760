.action-cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    * {
        margin-right: 0.5rem;

        &::after {
            padding-left: 0.6rem;
            content: '|';
        }

        &:last-child::after {
            content: '';
        }
    }

    .action-button-link {
        padding: 0;
    }
}
