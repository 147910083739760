patient-view-attachments {
    .note-box {
        margin-bottom: 10px;

        .file-filter {
            margin-right: 5px;
        }

        .patient-files-thumbnail {
            height: 32px;
            width: 32px;
            cursor: hand;
        }
    }
}
