.session-timer {
    margin-top: 2px;
}

session-timer {
    span {
        position: relative;
        top: -2px;
        left: 0;
    }

    i {
        color: $overcast;
        cursor: pointer;
        font-size: 20px;
    }

    a:hover {
        i {
            color: $ash;
        }
    }
}

.timer-notice {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px 10px 0 0;
}

#notice_div {
    position: absolute;
    z-index: 2000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: $snow;

    > a {
        position: absolute;
        top: 50%;
        font-size: 28px;
        padding: 0;
        vertical-align: middle;
        text-align: center;
        margin: auto;
        word-wrap: break-word;
    }
}
