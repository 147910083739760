ehr-encounter-note-modal {
    blockquote {
        border: none;
        padding: 0 15px;
        margin: 0;
        font-size: 14px;
    }

    ul {
        margin-bottom: 0;
    }

    .ehr-note-section {
        margin-bottom: 3em;
    }

    .ehr-note-root-element {
        margin-bottom: 5px;
    }

    .ehr-note-do-not-send-btn {
        color: $brand-danger;
    }

    .ehr-note-assessment-link {
        color: $brand-primary;
        text-decoration: underline;
    }

    .ehr-note-label {
        font-weight: bold;
    }

    .ehr-note-summary-note {
        overflow-wrap: break-word;
    }

    .ehr-note-question-table {
        @extend .table;
        @extend .table-bordered;

        thead {
            background-color: tint($matisse, 94%);
        }

        td {
            word-break: break-all;
        }
    }

    .ehr-note-tooltip {
        &.tooltip.left .tooltip-inner {
            background-color: $brand-primary;
        }

        &.tooltip.left .tooltip-arrow {
            border-right-color: $brand-primary;
            border-left-color: $brand-primary;
        }
    }
}
