.patient-high-risk-indicator,
patient-high-risk-indicator {
    label {
        margin-bottom: 3px;
    }

    ul {
        padding-left: 20px;
        -moz-padding-start: 20px;
        -webkit-padding-start: 20px;
    }

    .high-risk-indicator {
        color: #ffd700;
        cursor: pointer;

        &.active {
            color: shade(#ffd700, 20%);
        }

        &.inactive {
            cursor: initial;
        }
    }

    .tooltip.high-risk-reasons .tooltip-inner {
        @extend %therigy-tooltip-inner;
    }

    /* Hide arrow */
    .tooltip.high-risk-reasons .tooltip-arrow {
        display: none;
    }
}

.tooltip.high-risk-reasons .tooltip-inner {
    @extend %therigy-tooltip-inner;
}

/* Hide arrow */
.tooltip.high-risk-reasons .tooltip-arrow {
    display: none;
}
