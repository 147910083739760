.patient-activity-note-modal {
    .flex-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
    }

    .notes-modal-body {
        .no-notes-header {
            padding-bottom: 20px;
        }

        .note-box {
            padding-bottom: 1em;
        }

        .notes-byline {
            color: #888;
            font-size: 0.9em;
        }

        .notes {
            font-size: 1.1em;
        }

        .action-row {
            padding-top: 0.5em;
            padding-bottom: 1em;
        }
    }

    .notes-footer {
        background-color: #f0f0f0;
        padding: 15px;
        border-top: 1px solid #e5e5e5;

        .control-label {
            text-align: left;
            width: 100%;
            font-weight: normal;
        }

        .btn + .btn {
            margin-left: 5px;
        }
    }

    .select-note-status {
        display: flex;
        align-items: flex-end;

        .form-group {
            margin-bottom: 0;
        }
    }
}
