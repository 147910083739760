.uib-daypicker
  thead
    tr
      &:first-child
        height: 40px

      &:nth-child(2)
        height: 20px

    .uib-title
      padding: 0

.uib-datepicker-popup
  padding: 0

.uib-datepicker-popup
  li
    list-style: none

  .uib-day, .uib-month
    button
      border: none

  .uib-day, .uib-month, .uib-year
    button
      &.active
        background-color: $matisse !important
        span
          color: white !important

      span
        &.text-info
          color: black
          font-weight: bold
          text-decoration: underline
