@import '~@toast-ui/editor/dist/toastui-editor.css';
@import '~@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css';


.toastui-editor-contents h1 {
    font-size: 1.6rem;
}

.toastui-editor-contents h2 {
    font-size: 1.3rem;
}

.toastui-editor-contents h3,
.toastui-editor-contents h4 {
    font-size: 1.2rem;
    margin: 20px 0 2px;
}

.toastui-editor-contents h5,
.toastui-editor-contents h6 {
    font-size: 1rem;
}

.toastui-editor-contents table th {
    background-color: #7b8184;
}

.toastui-editor-contents ul > li::before {
    background-color: #222;
}

.toastui-editor-contents ol > li::before {
    color: #222;
}
