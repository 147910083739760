.meta-data-section {
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    .row-meta-data-section {
        padding: 5px 0;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
    }
}

.input-wrapper__patientpccvalue{
    .email-update-message {
      margin-left: .5rem;
    }
  }
