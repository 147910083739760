summary-notes {
    .summary-note-wrapper {
        font-size: 12px;
        overflow-y: scroll;
        min-height: 120px;
        height: 120px;
        margin-bottom: 15px;
        resize: both;
        border: solid 1px #b8b8b8;
        border-radius: 4px;
        padding: 5px;
        background-color: #eee;
    }

    .summary-note-creator {
        font-size: 10px;
        color: #636363;
    }

    .summary-note-text {
        margin-left: 5px;
        padding-bottom: 5px;
        white-space: pre-line;
    }
}
