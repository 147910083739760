assessment-question-radio {
    .assessment-question-radio-answers {
        margin-left: 10px;
    }

    .assessment-question-radio-answers > div {
        &:first-child {
            margin-top: 0;
        }
    }
}
