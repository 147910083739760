referral {
    .flex-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .add-create-action {
        a,
        button {
            font-size: 14px;
        }

        button {
            margin-right: 0;
        }

        a {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .header-referral {
        margin-bottom: 20px;
        margin-top: 40px;
    }

    .header-referral-information {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .nav-referral {
        margin-bottom: 40px;
        margin-top: 10px;

        a {
            border-right: solid 1px $link-color;
            padding-left: 3px;
            padding-right: 6px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                border-right: none;
            }
        }
    }

    .notification-referral {
        margin-top: 20px;
    }

    .referral-information-form {
        margin-top: 20px;
    }

    .main-header-patient-profile-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
