.view-message-page {
    .view-message-page-wrapper {
        margin: 0 70px;
        max-width: 1300px;

        .message-header {
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            background-color: #efefef;

            .message-table-header {
                margin: 0;
                padding: 8px;
                color: #333;
                font-size: 12px;
                font-weight: bold;
            }

            .message-table-header-delete {
                width: 50px;
            }
        }

        .message-row {
            display: flex;
            align-items: flex-start;
            border-bottom: 1px solid #ccc;
            cursor: pointer;

            .message-content-delete,
            .message-content {
                margin: 0;
                padding: 8px 16px;
                font-size: 12px;
            }

            .message-content-delete {
                width: 50px;
            }

            .message-content {
                padding: 10.5px;
            }

            .message-content-bold {
                font-weight: bold;
            }

            table {
                padding: 0;
                text-align: left;

                caption,
                tr {
                    margin: 0;
                    padding: 0;
                }

                th {
                    margin: 0;
                    padding: 8px;
                    color: #333;
                    border-bottom: 1px solid #ccc;
                    border-right: 1px solid #ccc;
                    background-color: #efefef;
                    font-size: 12px;
                }

                td {
                    margin: 0;
                    padding: 8px;
                    border-bottom: 1px solid #ccc;
                    font-size: 12px;
                }

                .filters {
                    padding: 10px 0;
                }

                select {
                    font-size: 14px;
                    width: 115px;
                }

                th:first-child {
                    width: 200px;
                }

                h3 {
                    cursor: pointer;
                    background: #f9f9f9;
                    border: 1px solid #ccc;
                    line-height: 1.42857143;
                    box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%);
                    padding: 6px 12px;
                    color: #444;
                    font-size: 1.5em;
                }
            }
        }

        .view-message-delete-btn {
            margin-left: -20px;
        }
    }
}
