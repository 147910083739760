patient {
    .main-header-patient-profile {
        margin-bottom: 20px;
    }

    .header-patient-profile {
        margin-top: 40px;
        margin-bottom: 20px;

        .header-checkbox-label-patient-profile {
            margin-left: 10px;
        }

        .collapsible-header-patient-profile {
            display: inline-block;

            &:hover {
                color: $link-color;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .profile-actions {
        margin-bottom: 20px;

        ul {
            margin: 0;
        }

        li {
            padding: 0;
            cursor: pointer;

            &::after {
                content: '|';
                padding: 0 15px;
            }

            &:last-child::after {
                content: '';
            }
        }

        a.disabled {
            color: $gray;
            pointer-events: none;
        }
    }

    .profile-patient-demographics {
        .block-section {
            margin-top: 20px;
        }
    }

    .meta-data-section {
        margin-top: 20px;
        padding-top: 10px;
        padding-bottom: 10px;

        .row-meta-data-section {
            padding: 5px 0;
            border-top: 1px solid $snow;
            border-bottom: 1px solid $snow;
        }
    }

    .add-create-action {
        a {
            font-size: 14px;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .patient-status {
        font-size: 16px;
        font-weight: normal;
    }

    .flex-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .predictive-risk-text {
        font-size: 12px;
        padding-top: 10px;
    }
}

.patient-demographic-content {
    .text-muted {
        color: $dimgray;
    }
}
