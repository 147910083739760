assignment-manager {
    @extend %work-queue;

    h1.page-title {
        background-color: #fff;
        border-bottom: 2px solid rgb(207 215 223 / 25%);
        font-size: 22px;
        height: 45px;
        padding: 0;
        line-height: 1;
        margin-bottom: 20px;
    }

    .assignment-manager-form {
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;

        .assign-tasks-btn {
            margin-left: 10px;
        }

        .form-group {
            margin-bottom: 0;
        }
    }

    .row-checkbox-container {
        display: flex;
        width: 16px;
        height: 16px;
        align-items: center;
        justify-content: center;

        .row-checkbox {
            background-color: #fff;
            color: $overcast;
        }
    }
}
