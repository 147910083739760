footer {
    background-color: #fff;
    border-top: 2px solid rgb(207 215 223 / 25%);
    font-size: 12px;
    padding: 15px 20px;
    width: 100%;

    a {
        padding: 0 5px;

        &:first-child {
            padding-left: 0;
        }
    }

    .copyright {
        float: right;
    }
}
