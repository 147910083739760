work-queue {
    @extend %work-queue;

    unassigned-medication-activity {
        margin-bottom: 20px;
    }

    .tooltip.patient-contact-info .tooltip-inner,
    .tooltip.notes-tooltip .tooltip-inner {
        @extend %therigy-tooltip-inner;

        .patient-contact-info {
            font-size: 12px;
            color: $manatee;
        }
    }
}

%work-queue {
    .page-wrapper-fluid {
        display: flex;
    }

    .therigy-table-tooltip {
        border-bottom: 1px dotted #1a9cd9;
    }

    aside {
        background-color: #f1f6f9;
        padding: 20px;
        width: 300px;
    }

    article {
        background-color: #fff;
        padding: 20px;
        width: 100%;

        .therigy-table tr {
            font-size: 12px;

            &.assigned-to,
            &.last-updated,
            &.last-updated span {
                white-space: nowrap;
            }
        }

        .patient-name-link {
            padding-right: 3px;
        }
    }

    .header-work-queue {
        margin-bottom: 20px;
        margin-top: 40px;
    }

    .filter-section {
        margin-bottom: 20px;

        .filter-section-header {
            color: $shark;
            font-size: 18px;
            margin-bottom: 0;
        }

        .filter-section-header-refine-by {
            margin-bottom: 0;
        }

        .bullet {
            vertical-align: 33%;
            font-size: 0.4em;
            color: $shark;
        }

        .filter {
            label.filter-header {
                color: $shark;
                font-weight: bold;
            }

            label,
            a {
                font-size: 12px;
                margin-bottom: 0;
            }

            .checkbox,
            .radio {
                margin: 0 0 -3px;
                color: $shark;
            }

            .checkbox input[type='checkbox'],
            .radio input[type='radio'] {
                margin-top: 2px;
            }
        }

        .form-group {
            label.control-label {
                color: $shark;
            }
        }

        .custom-date-range {
            margin-bottom: 5px;
        }

        .date-range {
            margin-bottom: 10px;

            datepicker {
                .form-group {
                    margin-bottom: 3px;
                }

                label {
                    font-weight: normal;
                }

                input {
                    @extend .input-sm;
                }

                button {
                    @extend .btn-sm;
                }
            }
        }
    }

    .filter-buttons {
        .btn-default.active {
            background-color: $matisse;
            color: #fff;
            box-shadow: none;
        }
    }

    .activity-count {
        color: $shark;
        font-size: 36px;
        line-height: 1;
    }
}
