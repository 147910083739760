assessment-manager {
    select {
        &.form-control {
            border-color: $snow;
            padding-right: 30px;
            cursor: default;
            transition: none;

            &:hover {
                background-color: #fff;
                border-color: $snow;
                box-shadow: none;
                transform: none;
            }

            &.form-control[disabled] {
                background: #eee;
            }
        }
    }

    .assessment-table-placeholder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $snow;
        height: 400px;
        border-radius: 3px;
        font-size: 14px;
        box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%);
        color: $biscay;
    }

    .header-assessment-manager {
        ul {
            margin-top: 10px;

            li {
                font-size: 14px;
            }
        }
    }

    .custom-assessments-section {
        margin-top: 30px;
    }

    .flex-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &.assessments-header {
            margin-bottom: 10px;
            padding: 10px 0;
        }
    }

    #assesment-manager-control-table tbody tr:last-child {
        border-style: hidden;
        border-top: 1px;
    }

    #assesment-manager-control-table tbody tr:last-child:hover {
        background: none;
    }

    #assesment-manager-control-table tbody tr:last-child td {
        padding-left: 0;
    }
}
