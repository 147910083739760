.form-button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;

    button {
        margin-right: 1rem;
    }

    button:last-of-type {
        margin-right: 0;
    }
}
