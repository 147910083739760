%patient-card-section-label {
    font-size: 14px;
    color: $manatee;
    font-weight: bold;
    margin-bottom: 3px;
}

%patient-card-section-note-counter {
    font-size: 14px;
    color: $manatee;
    margin-bottom: 3px;
    float: right;
}

patient-card {
    .patient-name-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        patient-high-risk-indicator {
            margin-right: 5px;
        }

        .patient-name {
            font-size: 18px;
            display: inline-block;

            a {
                padding-right: 5px;
            }
        }
    }

    .patient-card-section {
        &.patient-demographics {
            margin-top: 20px;
        }

        margin-bottom: 20px;

        .patient-card-section-label {
            @extend %patient-card-section-label;
        }

        .patient-card-section-note-counter {
            @extend %patient-card-section-note-counter;
        }
    }

    .patient-card-label {
        font-size: 12px;
        color: $manatee;
    }

    .patient-information {
        margin-bottom: 10px;

        .patient-information-gender {
            text-transform: capitalize;
        }
    }

    .patient-medication {
        padding-left: 20px;

        .patient-rxs {
            padding-left: 20px;
            margin-bottom: 10px;
        }
    }

    .patient-other-medication {
        .patient-other-medication-info {
            padding-left: 20px;
        }
    }

    .predictive-risk-text {
        background-color: #fff;
        border-radius: 4px;
        display: block;
        font-size: 12px;
        margin: 10px 0;
        padding: 2px;
    }
}
