question-diff {
    .diff-container {
        min-height: 55px;
        margin-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .diff-section {
        border-bottom: 1px solid black;
        margin-bottom: 15px;
        padding-bottom: 5px;
    }
}
