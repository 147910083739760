user-manager {
    aside {
        @extend .col-md-3;

        .create-new-user-link {
            display: block;
            font-size: 11px;
            margin-bottom: 20px;
        }

        nav {
            .branch-section {
                margin-bottom: 25px;
            }

            i {
                color: $link-color;
                cursor: pointer;
                font-size: 16px;
            }

            ul {
                color: $storm;
                list-style-position: outside;
            }
        }
    }

    article {
        @extend .col-md-9;

        iframe {
            width: 100%;
        }
    }
}
