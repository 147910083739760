.assessment-content-modal,
.content-library-resource-modal {
    .modal-dialog {
        width: 95%;
        height: 95%;
    }

    .modal-body {
        max-height: calc(100vh - 200px);
        overflow: auto;

        div:last-child {
            margin-bottom: 0;
        }
    }

    .content-modal-body {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: solid 1px $silver;
        margin-bottom: 40px;
    }

    .content-modal-content {
        padding: 0 10px;
    }

    .content-modal-content-group-name {
        background: $brand-primary;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        color: white;
        font-size: 20px;
        padding: 10px;
    }
}

assessment,
assessment-preview {
    #generate-pdf {
        position: relative;

        i,
        img {
            height: 45px;
            padding: 2px;
            position: absolute;
            top: 0;
            right: 0;
        }

        i {
            display: none;
        }

        img {
            cursor: pointer;
        }
    }

    .page-wrapper-fluid {
        position: absolute;
        top: $header-height + $navbar-height;
        bottom: 0 + $footer-height;
        left: 0;
        display: flex;
        width: 100%;
        overflow: hidden;

        // Required for Firefox
        min-height: 0;
    }

    .assessment-sidebar {
        background-color: #f1f6f9;
        padding: 20px;
        min-width: 300px;
        overflow: auto;
        flex: 0 0 300px;

        // Required for Firefox
        min-height: 0;

        .patient-card {
            margin-bottom: 40px;
        }

        > .engage-container {
            margin: 10px 0 40px;

            .engage-label {
                margin-right: 5px;

                @extend %patient-card-section-label;
            }
        }
    }

    .assessment-content {
        background-color: #fff;
        padding: 20px;
        overflow: auto;
        width: 100%;

        // Required for Firefox
        min-height: 0;

        .assessment-title {
            h1 {
                padding: 0;
                margin-bottom: 20px;
                font-size: 20px;
                display: inline-block;
            }

            .pdf-icon {
                float: right;

                img {
                    height: 45px;
                }
            }
        }

        .assessment-toolbar {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }
    }

    .assessment-scrollspy {
        background-color: #f1f6f9;
        padding: 5px;
        max-width: 200px;
        overflow: auto;
        flex: 0 0 auto;
    }

    .summary-note-text-area {
        margin-bottom: 20px;

        textarea {
            min-height: 115px;
        }

        h4 {
            margin-bottom: 10px;
        }
    }

    .assessment-delete-button {
        color: $brand-danger;
    }

    .assessment-scoring {
        margin-bottom: 20px;
    }

    .assessment-special-questions {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;

        .assessment-special-question {
            margin-right: 15px;
            min-width: 250px;
            max-width: 15%;
        }

        .medication-association-assessment-special-question {
            margin-right: 15px;
            min-width: 250px;
            max-width: 50%;

            #medication-association-input {
                color: #666;
            }

            .btn {
                width: 95%;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
            }

            .btn-default {
                color: #666;
            }

            .ui-select-choices-row-inner {
                width: 95%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    @include media('<=tablet') {
        font-size: 18px;

        .assessment-content {
            overflow: scroll;
        }

        .assessment-sidebar {
            display: none;
        }
    }

    @include media('<=640px') {
        .page-wrapper-fluid {
            top: $header-height + $navbar-height + $navbar-height;
        }
    }

    @include media('<=phone') {
        .assessment-content {
            flex: 0 0 map-get($breakpoints, 'phone');
        }
    }
}
