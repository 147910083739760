referral-manager-settings {
    .main-header-referral-manager-settings {
        margin-bottom: 20px;
    }

    .default-rules-referral-settings-manager {
        margin-left: 20px;
    }

    .flex-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .header-referral-manager-settings {
        margin-bottom: 20px;
        margin-top: 40px;
    }

    .add-create-action {
        a,
        button {
            font-size: 14px;
        }

        button {
            margin-right: 0;
        }

        a {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
