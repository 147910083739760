forgot-password {
    .forgot-password-form-container {
        min-height: 300px;
        overflow: hidden;

        .form-header {
            .title {
                font-size: 32px;
                margin-bottom: 5px;
            }

            .desc {
                margin-bottom: 25px;
            }

            .form-group {
                input {
                    height: 40px;
                }
            }
        }

        .error-message {
            padding: 4px 0;
            font-size: 16px;
            color: $brand-danger;
        }
    }

    .form-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        bottom: 0;

        a {
            font-size: 16px;
        }
    }
}
