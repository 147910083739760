.accordion-wrapper {
    padding-top: 30px;

    .accordion-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            &:hover {
                color: #166c9d;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}
