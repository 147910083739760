logout {
    .logout {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 300px;

        .title {
            font-size: 24px;
        }

        .spinner {
            color: $eastern-blue;
            font-size: 100px;
        }
    }
}
