[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none !important;
}

body,
html {
    margin: 0;
    padding: 0;
    background: #fff;
    height: 100%;
}

#app {
    height: 100%;
}

//Fix issue with react-icons in buttons and anchors being slightly off center
a > svg,
button > svg {
    position: relative;
    top: 2px;
}

.page-container {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content-wrap {
    padding-bottom: 5rem; /* Footer height */
    display: flex;
    flex-direction: column;
    flex: 1 100 auto;
    overflow: auto;
    position: relative;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5rem; /* Footer height */
}

// TODO: Replace with component
.global-info-message {
    color: $brand-info;
    font-size: 14px;
    margin-bottom: 20px;
}

%error-display {
    border-radius: 4px;
    padding: 10px 20px;
    border: 1px solid #999;
    font-weight: bold;
    color: $brand-danger;
    text-align: center;

    .error-display-header {
        margin-bottom: 20px;
    }
}

// TODO: Deprecate this once all error pages share the same component/template
.error_display {
    @extend %error-display;
}

.no-padding {
    padding: 0 !important;
}
