assessment-question-checkbox {
    .assessment-question-checkbox-answers {
        margin-left: 10px;
    }

    .assessment-question-checkbox-answers > div {
        &:first-child {
            margin-top: 0;
        }
    }
}
