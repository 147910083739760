.modal-content {
    tr {
        &.disabled {
            opacity: 0.2;
        }

        td.action {
            .form-check {
                text-align: center;
                padding-top: 8px;
            }
        }
    }

    .tbPatientDetails {
        padding: 6px 8px;
    }
}

.modal-footer {
    .footer-message {
        float: left;
        padding: 8px 0;

        .global-info-message {
            margin-top: 13px;
            margin-bottom: 0;
        }
    }
}
