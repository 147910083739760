/* mixin for multiline */
@mixin multi-line-ellipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;

    &::before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}

.assessment-patient-engagement-title,
.profile-patient-engagement-title {
    display: none;
}

.vuca-modal-video {
    padding: 0;

    iframe {
        width: 100%;
        height: 605px;
    }
}

.letter-filters-container {
    background-color: #fff;
    padding-bottom: 10px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 500;

    .filtering-letter {
        padding: 6px 13.8px;
        margin-left: 5px;
        margin-top: 10px;
        border: 1px solid $snow;
        border-radius: 2px;
        background-color: $snow;
        cursor: default;
        float: left;
        transition: all 0.5s ease;

        &:hover {
            box-shadow: 0 3px 7px 0 rgb(0 0 0 / 50%);
            transform: translate3d(0, -1px, 0);
        }

        &.active-letter {
            border-radius: 0 0 2px 2px;
            border-color: $biscay;
            background-color: $snow;
            box-shadow: 0 -3px 0 0 $biscay;
        }
    }
}

.video-tiles-container {
    margin-left: -10px;
    margin-right: -10px;

    .video-tile {
        width: 177px;
        height: 159px;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%);
        margin-left: 10px;
        margin-top: 15px;
        float: left;

        .video-thumbnail {
            width: 177px;
            height: 100px;
            background: $snow;
            box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%);
            border-radius: 2px 2px 0 0;
            padding-top: 23px;

            a.thumbnail-icon {
                background-image: url('#{$cdnURL}/images/video_library/play-icon.png');
                width: 59px;
                height: 57px;
                display: block;
                margin: auto;
                cursor: pointer;
            }
        }

        .video-title {
            display: block;
            margin-top: 20px;
            margin-left: 10px;
            margin-right: 10px;
            color: $matisse;
            font-size: 14px;
            text-decoration: underline;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }

        &.active-tile {
            box-shadow: 0 -3px 0 0 $biscay, 0 1px 3px 0 rgb(0 0 0 / 10%);

            a.thumbnail-icon {
                background-image: url('#{$cdnURL}/images/video_library/play-icon.png');
            }

            .video-title {
                color: $biscay;
                font-weight: bold;
                text-decoration: none;

                &::before {
                    font-family: $fa-style-family;
                    font-size: 10px;
                    content: '\f04b \00a0 \00a0';
                    white-space: pre;
                }
            }
        }
    }
}

.patient-communication-widget {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 20px;

    a {
        cursor: pointer;
        text-decoration: underline;
        font-size: 12px;

        &:hover {
            text-decoration: none;
        }
    }

    .edit-widget {
        float: right;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .patient-engagement-send-sent {
        color: $shark;

        i {
            font-size: 14px;
        }

        i.fa-spin {
            color: $shark;
        }

        i.fa-check {
            color: $brand-success;
        }
    }

    .widget-actions {
        padding-top: 5px;
    }

    .widget-content {
        background: #fff;
        border: 1px solid $snow;
        border-radius: 4px;

        .widget-content-opt-in {
            font-size: 14px;
            border-bottom: 1px solid $snow;
            padding: 5px 10px;

            i {
                color: $brand-success;
            }
        }

        .widget-content-links {
            padding: 5px 10px;

            .content-analytics {
                font-size: 12px;
            }
        }
    }

    .widget-content-action {
        padding-top: 10px;
    }
}

.profile-patient-outreach {
    .patient-communication-widget {
        border: 0;
        padding: 0;

        .profile-patient-engagement-title {
            display: block;

            h4 {
                display: inline-block;
            }
        }

        .assessment-patient-engagement-title {
            display: none;
        }

        .edit-widget {
            padding-top: 12px;
        }

        .profile-widget-content-action {
            display: none;
        }
    }
}

// TODO: These 'engage' styles can only exist while the assessment page livea on form.php
// Once it gets converted to angular, these styles will need to live in the appropriate sass file.

.content-player {
    width: 100%;
    margin-top: 40px;

    iframe {
        width: 100%;
        overflow: hidden;
    }

    &.active-content-player {
        iframe {
            height: 755px;
        }
    }
}

.patitent-engagement-opt-in {
    background-color: $snow;
    height: 100vh;
    padding-bottom: 20px;

    .view-title-header {
        background-color: #fff;
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%);

        .view-title-wrapper .view-title {
            color: $shark;
        }
    }

    .opt-in-module {
        max-width: 600px;
        background-color: #fff;
        padding: 40px;
        margin: 0 auto;
        margin-top: 40px;
        border-radius: 8px;
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 30%);

        img.opt-in-icon {
            display: block;
            margin: auto;
            margin-bottom: 60px;
        }

        strong.opt-in-title {
            font-size: 16px;
            padding-bottom: 10px;
            display: block;
        }

        p {
            color: $shark;
            font-size: 14px;
        }

        .opt-in-spam {
            background: $snow;
            padding: 10px;
            border: 1px solid $snow;
            border-radius: 4px;
            margin-top: 15px;
            font-size: 12px;
        }
    }

    .patient-content-page {
        max-width: 850px;
        margin: 0 auto;
    }
}
