access-view {
    .rolesWrapper {
        margin-top: 10px;
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
    }

    .privilege-description {
        margin-top: 10px;
    }

    .branch-name {
        font-size: 18px;
    }

    .roles-header {
        font-size: 18px;
    }

    .roles {
        flex: 0 0 225px;
        width: 225px;
        border: 1px solid #ccc;
        margin-right: 20px;
        margin-bottom: 20px;
        min-height: 250px;
        padding: 15px 20px 15px 15px;
        border-radius: 4px;
        word-break: break-all;

        .icon-container {
            position: relative;

            i {
                font-size: 18px;
                position: absolute;
                right: -15px;
                top: -10px;
            }
        }
    }
}
