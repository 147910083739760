// Typography
.section-title {
    padding-top: 40px;
    padding-bottom: 20px;

    h2 {
        font-size: 32px;
        color: #333;
    }

    .field-required {
        &::after {
            color: $brand-danger;
            content: '*';
            position: absolute;
            margin-left: 5px;
            top: 12px;
        }
    }

    .checkbox {
        margin-top: 10px;

        & > ::after {
            top: 0;
        }
    }

    &.medium {
        h2 {
            font-size: 22px;
        }
    }
}

label,
legend {
    font-weight: normal;

    &.field-required {
        font-weight: bold;

        &::after {
            color: $brand-danger;
            content: '*';
            margin-left: 5px;
        }

        &.suppress-asterisk {
            &::after {
                content: none;
            }
        }
    }
}
