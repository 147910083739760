.patientMessagingModal {
    .patienteducation-message {
        margin-top: 16px;
    }

    .header {
        font-size: 20px;
        margin-top: 20px !important;
        margin-bottom: 10px !important;
    }

    .has-label-error {
        color: #8d1000;
    }

    .enrollment-information {
        align-items: center;
        display: flex;
        font-size: 16px;
        justify-content: space-between;
        margin-bottom: 18px;
        white-space: nowrap;
    }

    .field-required {
        font-weight: bold;
    }

    .field-required::after {
        color: #8d1000;
        content: '*';
        position: absolute;
        margin-left: 5px;
    }
}

.patientMessagingViewModal {
    z-index: 1050;
}
