// TODO: Make the header less blue that what is is now.

app {
    height: 100%;

    [ui-view] {
        display: block;
        min-height: calc(100vh - #{$header-height} - #{$navbar-height} - #{$footer-height});
    }

    .page-wrapper {
        background: white;
        padding: 20px;
        min-height: calc(100vh - #{$header-height} - #{$navbar-height} - #{$footer-height});

        @extend .clearfix;
    }

    .page-wrapper-fluid {
        min-height: calc(100vh - #{$header-height} - #{$navbar-height} - #{$footer-height});

        @extend .clearfix;
    }

    .main-container {
        height: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
    }

    .main-content {
        flex: 1 0 auto;
    }

    h1.page-title {
        background-color: #fff;
        border-bottom: 2px solid rgb(207 215 223 / 25%);
        font-size: 22px;
        height: 45px;
        padding: 0 20px;
        line-height: 45px;
    }

    footer {
        background-color: #fff;
        border-top: 2px solid rgb(207 215 223 / 25%);
        font-size: 12px;
        padding: 15px 20px;
        width: 100%;

        a {
            padding: 0 5px;

            &:first-child {
                padding-left: 0;
            }
        }

        .copyright {
            float: right;
        }
    }
}
