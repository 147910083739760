pdx-pre-view-patient
  h1
    margin: 8px 0 22px

  .therigy-table
    td
      border: none !important
    tr
      border-top: 1px solid #ddd
      border-bottom: 1px solid #ddd

  .label
    color: #0f181d