content-library-monograph-editor {
    th[align='center'] {
        text-align: center;
    }

    .monograph-editor-placeholder {
        align-items: center;
        background: $snow;
        display: flex;
        flex-direction: column;
        height: 400px;
        justify-content: center;
    }
}
