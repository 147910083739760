.therigy-table {
    @extend %therigy-table;
}

.content-library-table {
    @extend %therigy-table;
}

%therigy-table {
    @extend .table;
    @extend .table-bordered;
    @extend .table-hover;

    &.loading-therigy-table {
        opacity: 0.5;

        &::after {
            color: $matisse;
            content: '\f1ce';
            font-family: $fa-style-family;
            left: 50%;
            position: absolute;
            top: 50%;
            z-index: 1000;

            @extend .fa-circle-notch;
            @extend .fa-spin;
            @extend .fa-5x;
        }
    }

    thead {
        background-color: tint($matisse, 94%);

        .ng-table-filters {
            background-color: #fff;
        }

        th {
            .sort-indicator {
                float: left;
            }

            &.filter {
                .btn-group {
                    float: left;
                }
            }

            .input-filter {
                @extend .input-sm;

                font-weight: bold;
                color: #283135;

                &.ng-not-empty {
                    background-color: #f1f6f9;
                }
            }

            .filter-select {
                @extend .input-sm;

                line-height: 1.5 !important;
            }

            border: none !important;
            border-bottom: solid 1px #ddd !important;

            &.sortable {
                cursor: pointer;
            }

            &.sortable.sort-asc,
            &.sortable.sort-desc {
                background-color: tint($matisse, 83%);
            }
        }
    }

    tbody {
        tr {
            &:hover {
                background-color: tint($matisse, 94%);
            }

            td {
                border-left: none !important;
                border-right: none !important;
            }
        }
    }
}

// The pagination bar and table counts
.ng-table-pager {
    margin-bottom: 40px;
    padding-bottom: 10px;

    .ng-table-pagination {
        margin: 0;
    }
}
