.program-detail-modal {
    .program-data {
        display: flex;
        margin-bottom: 0.5rem;

        .program-data-header {
            font-weight: bold;
            text-align: right;

            &::after {
                content: ':';
            }
        }
    }
}
