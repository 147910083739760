#prescribing_physician
  .physician_availability
    margin: 20px 0 0
    label
      input[type="checkbox"]
        margin: 0 6px 0
    .row
      .col-md-2:nth-last-child(1)
        float: none
        clear: both
  .uib-timepicker
    .uib-time
      input[type="text"]
        width: 35px
        padding: 2px
      .btn
        padding: 6px 2px
        width: 35px
    .btn-link
      padding: 2px 12px
    .btn
      .glyphicon
        font-size: 8px
  .physician_toggle_availability
    cursor: pointer

tr
  &#row_prescribing_physician, &#row_referral_setup
    display: table-row

prescriber-modal-component
  .modal-footer
    .message-left
      float: left