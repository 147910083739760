.additional-medication-content
  margin-bottom: 20px

.additional-medication-content
  .additional-diagnoses, .allergies
    label
      font-size: 12px
    .checkbox
      padding: 3px

  .additional-medication-table-header
    margin-top: 20px
    > label
      padding: 5px
      float: left
      font-size: 12px

  .additional-medication-table
    font-size: 12px
    > thead
      width: 100%
      display: inline-table

    > thead > tr > th,
    > tbody > tr > td
      border-bottom-width: 0
      font-size: 12px