referral-manager-settings-add-rx-status-rule {
    .ui-select-match-text {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 40px;
    }

    .ui-select-toggle > .btn.btn-link {
        margin-right: 10px;
        top: 6px;
        position: absolute;
        right: 10px;
    }

    .days-out-input {
        width: 100px;
        height: 34px;
        border: 1px solid #ccc;
        border-radius: 3px;
        padding-left: 10px;
    }
}
