.insurance-container {
    color: var(--shark);
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
}

.insurance-container-profile {
    color: var(--shark);
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 20px;
}

.react-datepicker-popper {
    z-index: 3;
}

.insurance-date-picker {
    display: block;
}

.insurance-readonly {
    background-color: #fff !important;
}

.tab-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    margin-top: 40px;
}

.plan-header {
    margin-top: 30px;
    margin-bottom: 30px;
}

.tab-panel-content {
    border: none;
}

.error-message-insurance {
    color: #8d1000;
    display: inline-flex;
}

.invalid-insurance-field {
    color: #8d1000 !important;
}

.invalid-insurance-form {
    border: 1px solid #8d1000 !important;
}

.show-insurance-pans {
    display: inline-block;
    float: left !important;
}

.show-insurance-pans:hover {
    color: #166c9d;
    text-decoration: underline;
    cursor: pointer;
}

.insurance-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.disable-insurance-plan {
    opacity: 0.6;
}

.plan-disabled {
    font-size: 8px;
    font-weight: bold;
    text-transform: uppercase;
    color: #8d1000;
}

.disabled-insurance {
    display: flex;
    justify-content: right;
    font-size: 13px;
    color: #8d1000;
}

.detach-modal-button:hover {
    background-color: darkred !important;
    color: white !important;
}

.block-section {
    margin-top: 20px;
}

.disable-insurance {
    padding: 20px;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 10px;
}

.grid-item {
    padding: 10px;
}

.nav-tabs {
    li.active {
        a,
        a:hover,
        a:focus {
            color: var(--biscay);
        }
    }

    li {
        a {
            color: var(--matisse);
        }
    }
}
