// TODO: Wrap these styles in the appropriate app-header tag. For now this is ok since there should only ever be one
// <header> tag in a web application.

#user-profile-popover {
    max-width: 600px;
    width: auto;

    .add-header-popover-heading {
        margin-bottom: 2rem;
    }

    .add-header-popover-subtext {
        color: $overcast;
        font-size: 12px;
    }
}

header {
    .logo {
        img {
            padding: 10px 0;
            height: 50px;
        }
    }

    .popover {
        max-width: 600px;
        width: auto;
    }

    $default-header-font-size: 14px;
    $default-header-height: 50px;

    align-items: center;
    justify-content: space-between;
    background-color: tint($matisse, 91%);
    display: flex;
    height: $default-header-height;
    padding: 0 20px;

    .search-bar {
        display: flex;
        align-items: center;
        margin-left: 10px;

        form {
            max-width: 400px;
        }
    }

    .branch {
        font-size: $default-header-font-size;
        height: $default-header-height;
        margin: 0 30px;
        display: flex;
        align-items: center;

        a {
            text-decoration: underline;
        }
    }

    .icon-bar {
        justify-content: flex-end;
        align-items: center;
        display: flex;

        .icon {
            margin: 0 10px;

            > i {
                color: $overcast;
                cursor: pointer;
                font-size: 20px;

                &:hover {
                    color: $ash;
                }
            }

            &.icon-envelope {
                position: relative;
            }
        }

        .message-counter-box {
            display: flex;
            position: absolute;
            top: -8px;
            right: -5px;
        }

        .message-counter {
            background: $brand-danger;
            color: white;
            border-radius: 4px;
            padding: 1px 3px;
            font-size: 12px;
        }
    }

    .add-header-popover-heading {
        .add-header-popover-username {
            font-size: 16px;
        }

        .add-header-popover-email {
            color: $overcast;
            font-size: 12px;
        }
    }

    .add-header-popover-btn-group {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        margin-top: 20px;

        button,
        a {
            margin: 0 5px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .btn-primary i {
            color: #fff;
        }
    }
}
