report-engine {
    .page-wrapper-fluid {
        position: absolute;
        top: $header-height + $navbar-height;
        bottom: 0 + $footer-height;
        left: 0;
        display: flex;
        width: 100%;
        overflow: hidden;

        // Required for Firefox
        min-height: 0;
    }

    iframe {
        width: 100%;
        height: 100%;
    }

    aside {
        background-color: tint($matisse, 90%);
        overflow: auto;
        display: flex;
        // Required for Firefox
        min-height: 0;
        min-width: 40px;

        a {
            cursor: pointer;
            font-size: 12px;
        }

        .menu-collapse {
            width: 40px;
            text-align: center;
            padding-top: 10px;
            color: #757d82;
            font-size: 18px;
        }

        .report-menu {
            float: left;
            width: 266px;
            padding-left: 20px;
        }

        label.report-section-header {
            color: $overcast;
            font-size: 18px;
            margin-bottom: 0;
            margin-top: 10px;
        }

        label.report-header {
            color: $ash;
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 0;
        }
    }

    article {
        background-color: #fff;
        overflow: auto;
        width: 100%;
        // Required for Firefox
        min-height: 0;
    }
}
