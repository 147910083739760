.rx-notes

  .rx-notes-wrapper
    overflow-y: auto
    max-height: 400px
    margin-top: 20px

  .rx-note-text
    white-space: pre-wrap


  .rx-note
    &:first-child
      padding-top: 0px

    padding-top: 20px
    font-size: 16px

    .rx-note-creator
      font-size: 12px
      color: #666
