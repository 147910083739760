referral-aetna-activity-appeal,
referral-aetna-activity-benefits-investigation,
referral-aetna-activity-financial-assistance,
referral-aetna-activity-prior-authorization {
    .nav-aetna-activity {
        margin-bottom: 40px;
        margin-top: 10px;

        a {
            border-right: solid 1px $link-color;
            padding-left: 3px;
            padding-right: 6px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                border-right: none;
            }
        }
    }
}
