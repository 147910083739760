.ui-select-multiple.ui-select-bootstrap.form-control {
    min-height: 34px;
    padding: 3px 7px !important; // https://github.com/angular-ui/ui-select/issues/1771
}

.form-group {
    .control-label {
        width: 100%;
    }
}

legend {
    font-size: 14px;
    border: none;
    margin-bottom: 0;
    margin-top: 0.5rem;
}
