therigy-loading-spinner {
    .message-therigy-loading-spinner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px;
        color: $matisse;
    }
}
