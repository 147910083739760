patient-labels {
    .patient-label {
        background: #777;
        color: white;
        padding: 5px 10px;
        border-radius: 4px;
        font-weight: 500;
        margin-right: 5px;
    }
}
