.question-manager {
    .answer-position {
        width: 52px;
        margin-right: 8px;
    }

    .question-manager-row {
        margin-bottom: 3px;
    }

    .question-type-options {
        margin: 20px 0 15px;
    }

    .preview-icon {
        vertical-align: top;
    }

    .question-modified {
        justify-content: right;
        font-size: smaller;
        margin-left: 50%;
    }

    .group_header {
        font-size: 1em;
        color: white;
        width: 100%;
        background: #20386c;
        padding: 6px;
        display: block;
    }

    .group {
        margin-bottom: 16px;
        border: 1px solid #20386c;
    }

    a {
        border-left: 1px solid black;
        padding-left: 3px;

        &:first-child {
            border-left: 0;
            padding-left: 0;
        }
    }

    .question-container {
        margin: 5px;

        .answer-row {
            margin-top: 3px;
        }

        .locked-question {
            background-color: #d3d3d3;
        }

        .question-linking-container {
            border: 1px solid lightgrey;
            min-height: 84px;
        }

        .question-options-container {
            margin-left: 10px;
            margin-right: 10px;
        }

        .question-error {
            font-size: 13px;
            font-weight: bold;
            color: #f00;
        }

        .question-header {
            border-bottom: 1px solid grey;
            margin-bottom: 5px;
            padding: 10px;
        }
    }
}
