.form-control.react-select {
    padding: 0;
    border: 0;
    min-height: 38px;
    height: unset;
    color: #0f181d;

    .react-select__menu {
        z-index: 3;
    }

    .react-select__placeholder,
    .react-select__single-value,
    .react-select__multi-value,
    .react-select__multi-value__label {
        color: $dimgray;
    }

    .react-select__control {
        .react-select__indicator.react-select__clear-indicator {
            color: var(--shark);
        }
    }
}

.input-group {
    .form-control.react-select {
        position: static;
    }
}

.has-error .form-control.react-select.react-select {
    border: 1px solid $brand-danger;
}

.react-select-input-group {
    .react-select__control {
        @include border-right-radius(0);
    }

    .input-group-btn {
        .btn {
            padding: 8px 12px;
        }
    }
}
