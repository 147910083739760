edit-patient {
    .footer-edit-patient {
        margin-top: 25px;

        button {
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .ui-select-bootstrap {
        min-height: 34px;
    }
}
