queue-manager {
    h1.page-title {
        background-color: #fff;
        border-bottom: 2px solid rgb(207 215 223 / 25%);
        font-size: 22px;
        height: 45px;
        padding: 0;
        line-height: 1;
        margin-bottom: 20px;
    }

    .branch-name {
        font-size: 18px;
    }

    .page-wrapper-fluid {
        display: flex;

        aside {
            background-color: tint($matisse, 90%);
            padding: 20px;
            width: 300px;

            ul {
                margin: 30px 0;
            }

            ul:last-child {
                margin-bottom: 0;
            }
        }

        article {
            background-color: #fff;
            padding: 20px;
            width: 100%;
        }

        .apply-to-all {
            display: inline-block;
            font-weight: normal;
            margin-right: 30px;
        }

        .branch-message {
            font-size: 10px;
            margin-left: -15px;
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
        }

        .add-column-btn {
            margin-right: 10px;
        }
    }
}
