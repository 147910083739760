#therapy-section {
    %action {
        a {
            font-size: 12px;
            position: relative;
            top: 5px;
        }
    }

    .therapy-container {
        background-color: #fff;
        color: #283136;
        border-radius: 4px;
        border: 1px solid #ddd;
        margin-bottom: 20px;

        .align-right {
            text-align: right;
        }

        .therapy-header {
            background-color: #f0f0f0;
            padding: 5px 10px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border-bottom: 1px solid #f0f0f0;

            &.therapy-no-medications {
                border-radius: 3px;
            }

            .therapy-action {
                @extend %action;
            }
        }

        .medication-container {
            padding: 5px 10px;
            border-bottom: 1px solid #f0f0f0;

            .medication-container-title {
                color: #283136;
                font-size: 12px;
            }

            .medication-action {
                @extend %action;
            }

            &.medication-ended {
                opacity: 0.6;

                &:hover {
                    opacity: 1;
                }

                .medication-end-label {
                    font-size: 8px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: #8d1000;
                }
            }

            &:hover {
                background-color: #f0f0f0;
            }

            .rx-container {
                border-left: 2px solid #ddd;
                padding-top: 10px;
                padding-left: 15px;
                padding-bottom: 5px;
                margin-top: 5px;

                .row {
                    display: flex;
                    padding: 0 15px;

                    .col-md-7th {
                        width: 14.27%;
                        padding: 0 3px;
                    }

                    .col-md-8th {
                        width: 12.5%;
                        padding: 0 5px;
                    }

                    a {
                        cursor: pointer;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.increase-popover-width {
    max-width: 400px;
}
