assessment-scoring {
    .assessment-score {
        padding-left: 10px;
        padding-bottom: 10px;
    }

    .assessment-scoring {
        color: $biscay;
        font-weight: bold;

        .assessment-scoring-value {
            color: $brand-danger;
        }
    }
}
