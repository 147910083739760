.profile-actions {
    margin: 20px 0;

    ul {
        margin: 0;
    }

    li {
        padding: 0;
        cursor: pointer;

        &::after {
            content: '|';
            padding: 0 15px;
        }

        &:last-child::after {
            content: '';
        }
    }
}
