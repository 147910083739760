.icd10-multi-select {
    padding: 10px;
    z-index: 3;
}

add-therapy-modal {
    .icd10-multi-select {
        width: 50em;
    }
}
