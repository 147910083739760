login {
    .login-form-container-sso-only {
        min-height: 110px;
    }

    .login-form-container {
        min-height: 300px;
    }

    .login-form-container-sso-only,
    .login-form-container {
        overflow: hidden;

        .form-group {
            &.email {
                margin-bottom: 25px;
            }

            &.password {
                height: 100%;
            }

            input {
                height: 40px;
            }
        }
    }

    .form-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        bottom: 0;

        a {
            font-size: 16px;
        }
    }

    .error-message {
        padding: 4px 0;
        font-size: 16px;
        color: $brand-danger;
    }
}
