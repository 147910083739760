add-patient {
    .footer-add-patient {
        margin-top: 25px;

        button {
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .add-patient-branch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $snow;
        padding: 15px 20px;
    }

    .add-edit-patient-validation {
        margin-top: 20px;

        ul {
            margin-bottom: 0;
        }
    }
}
