save-response-retry-modal {
    .save-response-retry-modal-body {
        font-size: 18px;
    }

    .save-response-retry-modal-footer {
        border-top: none;
    }

    .save-response-retry-modal-retry-btn-success {
        @extend .btn-success;

        width: 59px;
    }
}
