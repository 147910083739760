.patient-branch {
    padding: 1rem;

    .page-title {
        background-color: #fff;
        border-bottom: 2px solid #cfd7df40;
        font-size: 22px;
        line-height: 45px;
        padding: 0 20px;
    }

    .block-section {
        margin: 0;
    }

    .left-nav {
        background: #eee;
        padding: 8px;
    }

    .content {
        padding: 20px;
        zoom: 1;

        p {
            margin: 0.5em 0;
            padding: 0;
            line-height: 1.5em;
        }
    }

    .patient-name {
        line-height: 1.5em;
        margin: 0 0 0.5em;
        padding: 0;
    }

    .branch-type {
        font-size: larger;
    }

    .left {
        float: left;
        margin-right: 10px;
    }

    .submit-branch {
        margin-right: 1rem;
    }
}
