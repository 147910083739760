.add-therapy-modal {
    .modal-dialog {
        width: 1000px;
    }
}

add-therapy-modal {
    .rx-history-title {
        margin-top: 20px;
    }

    .block-section-buttons {
        & > button {
            width: 100%;
        }
    }

    .modal-body {
        .unassign-medication-details {
            margin-bottom: 20px;
        }

        .global-info-message {
            font-size: 12px;
        }

        .block-section-buttons {
            padding-top: 22px;
        }

        .form-group button {
            width: 100%;
        }

        .rx-row {
            animation-name: sections;
            animation-duration: 0.5s;
            margin-left: -15px;
            margin-right: -15px;
            padding-top: 10px;
            padding-left: 10px;
            padding-right: 10px;
            position: relative;

            .rx-row-remove {
                background-color: $snow;
                border-bottom-left-radius: 4px;
                cursor: pointer;
                font-size: 16px;
                display: none;
                position: absolute;
                top: 0;
                right: 0;

                &:hover {
                    color: $brand-danger;
                }
            }

            &:hover {
                background-color: $snow;

                .rx-row-remove {
                    display: block;
                }
            }
        }

        .rx-prescription-end {
            margin-bottom: 15px;
        }

        .input-group-btn select {
            border-color: #ccc;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 7px;
            padding-bottom: 7px;
        }

        .modal-section {
            background-color: $snow;
            box-shadow: 0 -1px 0 0 #ddd, 0 1px 0 0 #ddd;
            padding: 20px 15px;
            margin-left: -15px;
            margin-right: -15px;
            animation-name: sections;
            animation-duration: 0.5s;

            .global-info-message {
                margin-bottom: 0;
            }

            .modal-sub-action {
                margin-top: 5px;
                font-size: 14px;

                a {
                    cursor: pointer;
                }
            }
            // Transition Animations
            &.ng-show {
                transition: 0.5s linear all;
                opacity: 0;

                /* The finishing CSS styles for the enter animation */
                &.ng-show-active {
                    opacity: 1;
                }
            }
        }
    }

    .col-md-5th {
        @include make-md-column(2.4);
    }
}
