assessment-group {
    .assessment-group {
        border: solid 1px $silver;
        border-radius: 4px;
        margin-bottom: 20px;

        .assessment-group-header {
            color: white;
            cursor: pointer;
            background: $brand-primary;
            padding: 6px;
        }

        .assessment-group-body {
            > div > .assessment-group-questions {
                padding: 10px;
            }

            > div:last-child {
                padding-bottom: 0;
            }
        }

        .hide-assessment-group-body {
            display: none;
        }
    }
}
