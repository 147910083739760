content-library {
    .sub-navbar h1 {
        font-weight: $headings-font-weight;
        font-size: $font-size-large;
        text-align: left;
        padding-left: 7.8em;
        margin-top: 2em;
        padding-bottom: 1em;
    }

    .sub-navbar ul {
        list-style: none;
        text-align: left;
        padding-left: 10em;
        margin-bottom: 0;
    }

    .sub-navbar ul li {
        color: $silver;
        margin-right: 1.2em;
        display: inline-block;

        a {
            color: inherit;
            text-decoration: inherit;
            font-size: inherit;
            font-weight: inherit;
        }

        &:hover {
            color: rgb(213 213 213);
        }

        &.active {
            color: $gray-dark;
            border-bottom: 0.25rem solid $brand-primary;
            line-height: 2;

            &:hover {
                color: $gray-darker;
            }
        }
    }

    .section-background {
        border-top: 1.3px solid rgb(242 242 242);
    }

    .container-wrapper {
        align-items: left;
        margin-top: 1.5%;
        margin-left: 10em;
        margin-right: 10em;
        border-radius: 1%;
    }
}
