assessment-question-select {
    .assessment-question-select {
        width: 250px;

        .ui-select-match-text {
            width: 95%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .ui-select-choices {
            width: 200%;
        }

        .ui-select-choices-row-inner div {
            white-space: normal;
        }
    }
}
