.prescription-container {
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.rxFill-container {
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 90%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.prescriber-typeahead {
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding: 0;
    margin: 0;
    max-height: 32px;
}

.rxNumber-group {
    animation-name: sections;
    animation-duration: 0.5s;
    width: 100%;
    margin-left: -10px;
    margin-right: -10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    justify-items: center;
    display: flex;

    .rxNumber-group-remove {
        background-color: #f0f0f0;
        border-bottom-left-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        display: none;
        position: absolute;
        top: 0;
        right: 0;

        &:hover {
            color: #8d1000;
        }
    }

    &:hover {
        background-color: #f0f0f0;

        .rxNumber-group-remove {
            display: block;
        }
    }
}

row.rxNumber-group.rxFill-group {
    margin-left: -4px;
    margin-right: -4px;
}

.prescriber-select {
    opacity: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.view-rx-fill-container {
    width: 80%;
    border-left: 2px solid var(--dove);
    padding-bottom: 5px;
    margin-top: 5px;
    color: #283136;
    font-size: 12px;
    padding-left: 20px;

    .notes-button {
        display: flex;
        align-content: row;
    }

    .btn-default.custom-button-left {
        display: flex;
        align-content: row;
        padding: 1px;
        font-size: 12px;
    }
}

.btn-end-rx-number {
    padding: 6px 12px;
    margin-bottom: 0;
}

.end-rx-number-container {
    display: flex;
    align-items: center;
    padding-top: 12px;
}

.patient-rx-fill {
    padding-left: 20px;
    margin-bottom: 10px;

    .rx-fill-label {
        font-size: 12px;
        color: #636363;
    }
}

div.patient-prescription-container > div:nth-of-type(odd) {
    background: #f7f7f7;
}

.add-prescriber-modal {
    .text-muted {
        color: var(--dimgray);
    }
}
