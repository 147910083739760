activity-notes {
    .flex-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
    }

    .notes-modal-body {
        .no-notes-header {
            padding-bottom: 20px;
        }

        .note-box {
            padding-bottom: 1em;
        }

        .notes-byline {
            color: #888;
            font-size: 0.9em;
        }

        .notes {
            font-size: 1.1em;
        }

        .action-row {
            padding-top: 0.5em;
            padding-bottom: 1em;
        }
    }

    .notes-footer {
        background-color: $snow;
        display: none;

        .control-label {
            text-align: left;
            font-weight: normal;
        }
    }

    .select-note-status {
        display: flex;
        align-items: flex-end;

        label {
            float: left;
        }
    }
}
