.mass-texting {
    margin-left: 15px;

    h1 {
        background-color: #fff;
        font-size: 22px;
        height: 45px;
        line-height: 45px;
        margin-left: -15px;
    }

    .disclaimer {
        padding-top: 1%;
    }

    .modal-body-disclaimer {
        margin-top: 1%;
    }

    .form {
        padding-top: 1%;

        @media (min-width: 992px) {
            width: 50%;
        }

        .preview {
            margin-top: 3%;
        }

        .required {
            color: var(--brandDanger);
        }

        .float-right {
            float: right;
        }

        .selected-patients-cnt-text {
            padding-left: 0;
            padding-right: 5px;
        }
    }

    .line-height {
        line-height: 5pc;
    }
}

.activity-text-schedule {
    margin-left: 15px;

    h1 {
        background-color: #fff;
        font-size: 22px;
        height: 45px;
        line-height: 45px;
        margin-left: -15px;
    }

    ul {
        list-style-type: none;
        padding-left: 0;
    }

    .add-text-schedule {
        margin-top: 1%;
    }

    .equal-height-row {
        display: flex;
    }

    .equal-height-row .col {
        display: flex;
        flex-direction: column;
    }

    .equal-height-row .col > * {
        flex-grow: 1;
    }

    .float-right {
        float: right;
        margin-left: 10px;
    }
}
