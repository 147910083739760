.main-menu ul {
    list-style: none;
    background-color: #166c9d;
    text-align: center;
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 1000;
    cursor: pointer;

    li {
        border-bottom: none;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        text-align: left;
        display: inline-block;
        margin-right: -4px;
        float: left;

        /* Sub Menus */
        ul {
            position: absolute;
            display: none;
            width: inherit;

            li {
                width: 100%;
                height: auto;
                position: relative;

                ul {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 30%);
                    display: none;
                    position: absolute;
                    left: 200px;
                    top: 0;
                }
                // TODO: Convert to click events
                &:hover > ul {
                    display: block;
                }
            }
        }
        // TODO: Convert to click events
        &:hover > ul {
            display: block;
        }
    }

    a {
        font-size: 14px;
        text-decoration: none;
        color: #fff;
        display: block;
        padding-left: 20px;
        padding-right: 20px;

        &:hover,
        &:active,
        &.active {
            background-color: var(--biscay);
        }
    }

    &.dropdown {
        width: 200px;
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 50%);

        i {
            margin-left: 5px;
        }

        a {
            width: 100%;
            line-height: 20px;
            padding-top: 9px;
            padding-bottom: 9px;
        }
    }
}
