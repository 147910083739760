insights-canned-reports {
    .container-fluid {
        padding: 20px;
        min-width: 1150px;
    }

    h2 {
        font-weight: 300;
        margin-bottom: 10px !important;
    }

    .insights_error .error_display {
        border: 0;
    }

    .row.no-gutters {
        margin-right: 0;
        margin-left: 0;
        padding: 0;
    }

    .row.no-gutters > [class^='col-'],
    .row.no-gutters > [class*=' col-'] {
        padding-right: 0;
        padding-left: 0;
    }

    .no-padding {
        padding: 0 !important;
        margin: 0 !important;
    }

    .app-header {
        background-color: $biscay;
        height: 60px;
        color: #fff;

        .logo-brand {
            height: 60px;
            line-height: 60px;
            padding-left: 20px;
            font-size: 24px;

            img {
                width: 100%;
            }
        }

        .insights-report-menu-filter {
            position: relative;
            line-height: 60px;

            input {
                color: white;
                background: darken($biscay, 5%);
                border: none;
                height: 28px;
                font-size: 14px;
                line-height: 14px;
                padding: 6px 0 6px 30px;

                ::placeholder {
                    color: white;
                }
            }

            .fa-search {
                position: absolute;
                bottom: 25px;
                left: 8px;
            }
        }

        .search-reports {
            height: 60px;
            display: table-cell;
            vertical-align: middle;
        }

        .search-reports .form-control {
            width: auto;
            height: auto;
        }

        .search-reports .form-control::before {
            content: '\f002';
            font: normal 14px $fa-style-family;
            color: #aaa;
            position: absolute;
            left: 10px;
            top: 11px;
            transition: all 0.3s ease;
        }

        .search-reports .form-control input {
            width: 350px !important;
            height: 32px;
            padding-left: 30px;
        }

        .search-reports .input-group-addon {
            background-color: #fff;
        }

        .search-reports input.search {
            width: 300px;
            font-size: 14px;
            border: 1px solid #ddd;
            border-radius: 4px;
            padding-top: 10px;
            padding-bottom: 10px;
            text-indent: 40px;
        }
    }

    .user-bar {
        font: 12px Lato, Helvetica, sans-serif;
        line-height: 30px;

        .signin-user {
            .inner-content {
                color: #666;
                float: right;

                .user-name {
                    color: #333;
                    font-weight: bold;
                }

                &.col-xm-12 {
                    float: none;
                }
            }
        }

        a {
            text-decoration: underline;
            font-weight: bold;
        }

        session-timer div {
            display: inline;
        }
    }

    .app-header .signin-user i {
        color: #1d386f;
        margin-left: 10px;
    }

    #ReportViewerMenuContainer {
        background-color: #fff;
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 0 1px rgb(0 0 0 / 8%);
        border-radius: 5px;
    }

    .reports-navigation a {
        font-size: 12px;
        padding: 6px;
        margin: 0;
        display: block;
        text-decoration: none;
        color: #666;
        white-space: normal;
    }

    .reports-navigation a:hover {
        background-color: $snow;
        color: #333;
    }

    .reports-navigation ul {
        margin: 0;
        padding: 0;
    }

    .reports-navigation > ul.close > ul {
        display: none;
    }

    .reports-navigation ul > a {
        font-weight: bold;
    }

    .reports-navigation li {
        padding: 0 0 0 20px;
        list-style: none;
    }

    .reports-navigation li > a.active {
        background-color: $snow;
        box-shadow: inset 5px 0 0 0 #1d386f;
        color: #333;
    }

    .report-options {
        background-color: $snow;
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 0 1px rgb(0 0 0 / 8%);
        border-radius: 5px 5px 0 0;
        padding: 20px;
        border-bottom: 0;

        h1 {
            font-size: 28px;
            color: #333;
            font-weight: normal;
            margin: 0;
            margin-bottom: 30px;
        }

        .select-all {
            font-size: 12px;
            color: #1d386f;
            float: right;
            padding-top: 2px;
        }

        .toggle {
            cursor: pointer;
        }
    }

    .form-actions {
        float: right;
    }

    .loader {
        border: 10px solid #f3f3f3;
        border-radius: 50%;
        border-top: 10px solid #3498db;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    insights-canned-report-multiselect-filter {
        min-height: 85px !important;
    }

    .ui-select-match-item {
        background: none !important;
        color: #333 !important;
        outline: none !important;
        border-color: #adadad !important;
        box-shadow: none !important;
    }

    #reportViewer {
        position: relative;
        height: 100vh;
        overflow: hidden;
        border: 0;
        border-top: 0;
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 0 1px rgb(0 0 0 / 8%);
        border-radius: 0 0 5px 5px;
        background: #fff url('#{$cdnURL}/images/insights/insights_logo.svg') center center no-repeat;

        .trv-error-pane {
            font-size: 16px;
        }

        .placeholder {
            position: absolute;
            top: 20%;
            width: 100%;
            height: 40px;
            text-align: center;
        }
    }

    #ReportViewerContainer {
        .col-xm-12 {
            padding: 0;
        }

        .content {
            padding: 40px 20px 0 !important;
        }
    }

    footer {
        padding: 20px 60px;
        color: #666;

        a {
            padding: 0 10px;
        }
    }

    @media (max-width: 992px) {
        #ReportViewerContainer {
            padding: 20px 60px;
        }

        .user-bar .signin-user .inner-content {
            float: none;
        }
    }
}
