active-patient-fields {
    label {
        display: inline-block;
    }

    .group-name {
        display: inline-block;
        font-weight: bold;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .field-group-start {
        margin-top: 10px;
    }

    .field-group-end {
        margin-bottom: 30px;
    }

    .field:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}
