.medication-information-header, .medication-information-content
  margin-bottom: 20px
  .edit-prescriber
    font-size: 12px
    padding-top: 2px
    color: #ffffff
    float: right
  .medication-table-header
    margin-top: 20px
    > label
      padding: 5px
      float: left
      font-size: 12px
    .medication-name
      width: 20%
    .prescriber
      width: 20%
    .rx-number
      width: 16%
    .rx-number-start, .rx-number-end
      width: 16%
    .actions
      width: 12%
      padding-right: 20px

  .table-wrapper
    max-height: 250px
    overflow-y: auto

.medication-information-table

  .ui-select-search, .ui-select-match-text, .ui-select-toggle
    font-size: 12px

  .ui-select-toggle
    height: 30px !important

  .input-group
    input
      height: 30px !important
      font-size: 12px
    .input-group-addon
      height: 30px !important

  .input-group-addon
    padding: 2px
    cursor: pointer

  > thead > tr > th,
  > tbody > tr > td
    font-size: 12px

    &.medication-name
      width: 20%
    &.prescriber
      width: 20%
    &.rx-number
      width: 16%
    &.rx-number-start
      width: 16%
    &.rx-number-end
      width: 16%
    &.actions
      width: 12%

  > tbody
    > tr
      height: 50px
      > td
        .ui-select-search, .ui-select-match-text
          font-size: 12px
        > .checkbox
          margin: 0
          > label
            cursor: pointer
