.therapy-status-notes

  .therapy-status-notes-wrapper
    overflow-y: auto
    max-height: 400px

  .therapy-status-note
    &:first-child
      padding-top: 0
    padding-top: 20px
    font-size: 16px

    .therapy-status-note-creator
      font-size: 12px
      color: $overcast