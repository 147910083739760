root {
    .root {
        height: 100%;

        > [ui-view] {
            min-height: 100vh;
        }
    }

    .auth {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .logo {
            img {
                height: 100px;
            }
        }

        .support-info {
            width: 435px;
            text-align: center;
            color: $biscay;
            font-size: 14px;
            font-weight: bold;
            margin: 0 auto;

            a,
            span {
                color: $eastern-blue;
            }
        }

        .auth-box {
            a {
                color: $eastern-blue;
            }

            background-color: white;
            border-radius: 4px;
            width: 450px;
            padding: 20px;
            margin: 55px auto;
            box-shadow: 0 -1px 2px rgb(0 0 0 / 7%), 0 5px 15px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%);
        }
    }
}
