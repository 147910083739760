// Extra info tooltip styles (specific to tooltip position setting)
%assessment-question-extra-info-tooltip {
    &.tooltip.left-bottom .tooltip-inner {
        background-color: $brand-primary;
    }

    &.tooltip.left-bottom .tooltip-arrow {
        border-right-color: $brand-primary;
        border-left-color: $brand-primary;
    }
}

%assessment-question {
    padding: 10px;
}

%assessment-question-label {
    position: relative;
}

%assessment-question-message {
    padding-right: 20px;
}

%assessment-question-extra-info-icon {
    position: absolute;
    right: 0;
    top: 0;
}

// Black box warning
.bbw1 {
    border: 5px solid black;
    margin: 5px 0;
    padding: 5px;
}

// Special questions do not need any padding
.assessment-special-question {
    .assessment-question {
        padding: 0;
    }
}

assessment-question {
    .assessment-question {
        @extend %assessment-question;
    }

    .assessment-question-children {
        margin-left: 20px;
        margin-top: 5px;
    }

    .assessment-question-extra-info-icon {
        @extend %assessment-question-extra-info-icon;
    }

    .assessment-question-extra-info-tooltip {
        @extend %assessment-question-extra-info-tooltip;
    }

    // This is the container for the message and the extra info icon
    .assessment-question-label {
        @extend %assessment-question-label;
    }

    .mobile-answer-label {
        padding-bottom: 5px;
    }

    .assessment-question-message {
        @extend %assessment-question-message;
    }

    // TODO: Apply fix input-group calendar not turning red on error site-wide
    .has-error .assessment-question-date-calendar {
        border-color: $brand-danger;
        color: $brand-danger;
    }

    .assessment-question-score {
        font-weight: bold;
        color: $brand-danger;
    }
}
